import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useGlobalContext } from '../components/GlobalContextProvider/GlobalContextProvider';

function useDDNCItems(initialItems, shouldDateRangeChange = true) {
  const { setDateRangeState } = useGlobalContext();

  const [lastNewsDate, setLastNewDate] = useState(new Date());
  const filteredItems = useMemo(() => {
    let lastNewsDateVar = null;

    return initialItems
      ? initialItems.filter((el, index) => {
          if (index === 0) {
            lastNewsDateVar = el.publishedDate;
            setLastNewDate(lastNewsDateVar);

            if (shouldDateRangeChange) {
              setDateRangeState([
                {
                  startDate: new Date(lastNewsDateVar),
                  endDate: new Date(lastNewsDateVar),
                  key: 'selection',
                },
              ]);
            }
          }

          return dayjs(el.publishedDate).isSame(lastNewsDateVar, 'day');
        })
      : [];
  }, [initialItems]);

  const [usaNewsItems, setUsaNewsItems] = useState([]);
  const [intNewsItems, setIntNewsItems] = useState([]);
  useEffect(() => {
    const usaNews = [];
    const intNews = [];

    for (const item of filteredItems) {
      if (item.countryList.findIndex((el) => el.name === 'USA') !== -1) {
        usaNews.push(item);
      } else {
        intNews.push(item);
      }
    }

    setUsaNewsItems([...usaNews]);
    setIntNewsItems([...intNews]);
  }, [filteredItems]);

  return { lastNewsDate, usaNewsItems, intNewsItems };
}

export default useDDNCItems;
