import Link from 'next/link';
import React from 'react';
import { NoUnderlineAnchor } from '../../../shared-styled/typography/Anchors';
import UniversalLink from '../../UniversalLink/UniversalLink';
import {
  AgitationBlockContainer,
  AgitationBlockTitle,
  AgitationBlockDescription,
  AgitationButtonWrapper,
  AgitationBlockSeparator,
  AgitationBlockBody,
  RelativeAgitationBlock,
  AlignedTextButton,
} from './AgitationBlock.styled';

const AgitationBlock = ({ data }) => (
  <AgitationBlockContainer $background={data.backgroundImage}>
    <AgitationBlockBody>
      <AgitationBlockTitle>{data.title}</AgitationBlockTitle>
      <RelativeAgitationBlock>
        <AgitationBlockDescription html={data.description} />
        <AgitationBlockSeparator />
      </RelativeAgitationBlock>
      <AgitationButtonWrapper>
        <UniversalLink href={data.buttonLink} passHref>
          <NoUnderlineAnchor>
            <AlignedTextButton>
              {data.buttonTitle || 'Follow the link'}
            </AlignedTextButton>
          </NoUnderlineAnchor>
        </UniversalLink>
      </AgitationButtonWrapper>
    </AgitationBlockBody>
  </AgitationBlockContainer>
);

export default AgitationBlock;
