import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import pxToRem from '../../../utils/pixelsToRem';

export const NewsBlockContainer = styled(Flex)(
  css({
    padding: ['50px 20px', '60px 36px', '60px 95px'],
    flexDirection: 'column',
    color: 'text',
  }),
);

export const NewsBlockTitle = styled.h3(
  css({
    mb: '70px',
    fontFamily: '"Urbanist"',
    fontWeight: '300',
    fontSize: pxToRem(40),
    lineHeight: pxToRem(40),
    letterSpacing: '0.01em',
    cursor: 'pointer',

    '&:hover': {
      color: 'hoverLink',
    },
  }),
);
