import React from 'react';
import { DDNC_NEWS_URL } from '../../../consts/urls';
import useDDNCItems from '../../../hooks/useDDNCItems';
import { NoUnderlineAnchor } from '../../../shared-styled/typography/Anchors';
import DDNCBlock from '../../DDNCBlock/DDNCBlock';
import UniversalLink from '../../UniversalLink/UniversalLink';
import { NewsBlockContainer, NewsBlockTitle } from './NewsBlock.styled';

const NewsBlock = ({ newsData }) => {
  const { usaNewsItems, intNewsItems } = useDDNCItems(newsData, false);

  return (
    <NewsBlockContainer>
      <UniversalLink href={DDNC_NEWS_URL} passHref>
        <NoUnderlineAnchor>
          <NewsBlockTitle>In Our Sights Newsletter</NewsBlockTitle>
        </NoUnderlineAnchor>
      </UniversalLink>

      <DDNCBlock items={usaNewsItems.slice(0, 4)} title='US News' />
      <DDNCBlock items={intNewsItems.slice(0, 6)} title='International News' />
    </NewsBlockContainer>
  );
};

export default NewsBlock;
