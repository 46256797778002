import styled from '@emotion/styled';
import Link from 'next/link';
import { css, Flex } from 'theme-ui';
import RawHtml from '../../components/RawHtml';
import UniversalLink from '../../components/UniversalLink/UniversalLink';
import pxToRem from '../../utils/pixelsToRem';

export const Wrapper = styled(Flex)(
  css({
    flexDirection: 'column',
  }),
);

export const WhatsNewBlock = styled(Flex)(
  css({
    padding: ['50px 20px', '60px 36px', '90px 95px'],
    flexDirection: 'column',
    color: 'text',
  }),
);

export const WhatsNewBlockTitle = styled.h3(
  css({
    fontFamily: '"Urbanist"',
    fontWeight: '300',
    fontSize: pxToRem(40),
    lineHeight: pxToRem(40),
    letterSpacing: '0.01em',
  }),
);

export const ContentContainer = styled(Flex)(
  css({
    flexDirection: ['column', 'column', 'row'],
    gap: ['50px', '60px', '16px', '20px', '30px'],
    mt: '60px',
  }),
);

export const ContentLink = styled(UniversalLink)(
  css({
    color: 'transparent',
  }),
);

export const ContentBlock = styled(Flex)(
  css({
    flexDirection: 'column',
    cursor: 'pointer',
    flexGrow: '1',
    width: '100%',

    '&:hover h2': {
      color: 'hoverLink',
    },
  }),
);

export const ContentTitle = styled.h2(
  css({
    fontWeight: '600',
    fontSize: pxToRem(24),
    lineHeight: pxToRem(28),
    letterSpacing: '0.01em',
    color: 'secondaryGreen',
    pb: ['16px', '20px', '28px'],
    textDecoration: 'none',
  }),
);

export const ContentImage = styled.img(
  css({
    objectPosition: '50% 50%',
    objectFit: 'contain',
    width: '100%',
    height: ['220px', '320px', '230px', '270px', '300px'],
    pb: ['32px', '32px', '40px'],
  }),
);

export const ContentDescription = styled(RawHtml)(
  css({
    color: 'text',
  }),
);
