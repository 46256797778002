import { Box, css, Flex } from 'theme-ui';
import theme from '../../../lib/theme';
import styled from '../../../lib/transient-styled';
import pxToRem from '../../../utils/pixelsToRem';
import Image from '../../Image';
import RawHtml from '../../RawHtml';

export const WeaponsBlockContainer = styled(Flex)(
  css({
    flexDirection: 'column',
    color: 'text',
  }),
);

export const WeaponsHead = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: '60px',
    my: '120px',
    ml: '100px',
    [`@media (max-width: ${theme.breakpoints[1]})`]: {
      gap: '40px',
      my: '40px',
      mx: '20px',
    },
  }),
);

export const WeaponsHeadTitle = styled.h2(
  css({
    fontFamily: 'Urbanist',
    fontWeight: '200',
    fontSize: pxToRem(40),
    lineHeight: pxToRem(40),
    letterSpacing: '0.01em',
  }),
);

export const WeaponsHeadBody = styled.div(
  css({
    position: 'relative',
    width: ['80%', '60%'],
    ml: ['40px', '70px'],
  }),
);

export const WeaponsHeadDescription = styled(RawHtml)(
  css({
    fontSize: pxToRem(18),
    lineHeight: pxToRem(28),
    letterSpacing: '0.01em',
  }),
);

export const WeaponsHeadSeparator = styled.p(
  css({
    position: 'absolute',
    top: '-16px',
    left: '-32px',
    width: '1px',
    height: '80px',
    borderLeft: '1px solid',
    borderColor: 'text',
  }),
);

export const WeaponsBody = styled(Flex)(
  css({
    flexDirection: 'column',
    gap: '60px',
    mb: ['60px', '80px', '120px'],
    mx: [0, '100px'],
  }),
);

export const Weapon = styled(Flex)(
  css({
    justifyContent: 'space-evenly',
    gap: '60px',
    ':nth-of-type(2n)': {
      flexDirection: 'row-reverse',
    },
  }),
);

export const WeaponTextColumn = styled(Flex)(
  css({
    flexDirection: 'column',
    justifyContent: 'center',
    width: '590px',
    fontFamily: 'Lato',
    lineHeight: pxToRem(28),
  }),
);

export const WeaponTextTitle = styled.h3(
  css({
    fontWeight: '600',
    fontSize: pxToRem(24),
    letterSpacing: '0.05em',
    mb: '20px',

    [`@media (max-width: ${theme.breakpoints[1]})`]: {
      fontWeight: '700',
      color: 'white',
      zIndex: '3',
    },
  }),
  ({ $isLink }) =>
    css({
      cursor: $isLink && 'pointer',

      '&:hover': {
        color: $isLink && 'hoverLink',
      },
    }),
);

export const WeaponMobileWrapper = styled(Box)(
  css({
    width: '100%',
  }),
);

export const WeaponMobileImageWrapper = styled(Box)`
  position: relative;
  padding: 60px 60px 40px 60px;
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
`;

export const WeaponTextDescription = styled(RawHtml)(
  css({
    lineHeight: pxToRem(28),
    letterSpacing: '0.01em',
    margin: ['32px 20px', '32px 20px', '0'],
  }),
);

export const WeaponImageColumn = styled(Flex)(
  css({
    width: '590px',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

export const WeaponsImage = styled(Image)(({ isLink }) =>
  css({
    cursor: isLink && 'pointer',
  }),
);
