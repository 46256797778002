import styled from '@emotion/styled';
import { css } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

export const Divider = styled.div(
  css({
    border: '1px solid',
    borderColor: 'searchBorder',
    margin: '32px 0',
    display: ['block', 'none'],
  }),
);

export const RegionName = styled.h1(
  css({
    position: 'relative',
    margin: ['20px 0 14px 24px', '20px 0 14px 54px'],
    fontWeight: '600',
    fontSize: pxToRem(18),
    lineHeight: pxToRem(24),
    textTransform: 'uppercase',

    '&::before': {
      content: '""',
      position: 'absolute',
      left: '-24px',
      top: '-8px',
      width: '4px',
      height: '40px',
      background: 'secondaryGreen',
      borderRadius: '10px',
    },
  }),
);
