import React from 'react';
import { Grid } from 'theme-ui';
import { NEWS_LIST_URL } from '../../consts/urls';
import PostPreview from '../PostPreview';
import { Divider, RegionName } from './DDNCBlock.styled';

const DDNCBlock = ({ items, title }) =>
  items.length > 0 && (
    <>
      <RegionName>{title}</RegionName>
      <Grid
        columns={['2fr', '2fr', '1fr 1fr']}
        p={['70px 0']}
        gap={[0, 0, '80px']}
      >
        {items.map((item) => (
          <div key={item.slug}>
            <PostPreview {...item} href={`${NEWS_LIST_URL}/${item.slug}`} />
            <Divider />
          </div>
        ))}
      </Grid>
    </>
  );

export default DDNCBlock;
