import React from 'react';
import { Link as LinkThemeUI } from 'theme-ui';
import { ACTIVATE_FULL_BAR } from '../../../consts/events';
import eventBus from '../../../lib/eventBus';
import { NoUnderlineAnchorBlock } from '../../../shared-styled/typography/Anchors';
import { IS_NEXT_EXPORT } from '../../../utils/export';
import { useGlobalContext } from '../../GlobalContextProvider/GlobalContextProvider';
import UniversalLink from '../../UniversalLink/UniversalLink';
import {
  HeroBlockContainer,
  TrustedSourceColumn,
  TrustedSourceTitle,
  AlignedTextButton,
  WhatsNewColumn,
  ColumnContentTitle,
  ColumnContentImage,
  WhatsNewColumnTitle,
  TrustedSourceDescription,
  SearchContainer,
  HeroBlockSearchBar,
  WeaponBlock,
  Search,
  WeaponsSection,
  SearchIcon,
  RelativeContainer,
  WeaponLink,
  TextButtonWrapper,
} from './HeroBlock.styled';

const domain = process.env.NEXT_PUBLIC_HOST_ENV
  ? `https://${process.env.NEXT_PUBLIC_HOST_ENV}`
  : 'https://dev.mp.quintagroup.com';

const HeroBlock = ({ trustedSourceData, weaponsBlockData, whatsNewData }) => {
  const { isLogged, setIsSearchActive } = useGlobalContext();

  const activeFullSearchBar = () => {
    setIsSearchActive(true);
    eventBus.emit(ACTIVATE_FULL_BAR);
  };

  const WhatsNewLink = IS_NEXT_EXPORT ? UniversalLink : LinkThemeUI;

  return (
    <HeroBlockContainer
      $background={trustedSourceData.backgroundImage}
      $isLogged={isLogged}
    >
      {isLogged ? (
        <SearchContainer>
          <HeroBlockSearchBar>
            <RelativeContainer>
              <SearchIcon />
              <Search onClick={activeFullSearchBar} placeholder='Search ' />
            </RelativeContainer>
            <WeaponsSection>
              {weaponsBlockData.dynamicContent.map((el) => (
                <WeaponLink
                  key={el.value.title}
                  href={el.value.link.path || ''}
                >
                  <WeaponBlock>{el.value.title}</WeaponBlock>
                </WeaponLink>
              ))}
            </WeaponsSection>
          </HeroBlockSearchBar>
        </SearchContainer>
      ) : (
        <>
          <TrustedSourceColumn>
            <TrustedSourceTitle>{trustedSourceData.title}</TrustedSourceTitle>
            <TrustedSourceDescription html={trustedSourceData.description} />
            <TextButtonWrapper>
              <UniversalLink
                href={trustedSourceData.buttonLink}
                isBlock={true}
                passHref
              >
                <NoUnderlineAnchorBlock>
                  <AlignedTextButton>
                    {trustedSourceData.buttonTitle || 'Follow the link'}
                  </AlignedTextButton>
                </NoUnderlineAnchorBlock>
              </UniversalLink>
            </TextButtonWrapper>
          </TrustedSourceColumn>
          <WhatsNewColumn>
            <WhatsNewColumnTitle>What&#39;s New</WhatsNewColumnTitle>
            {whatsNewData.map(
              (el) =>
                el.image && (
                  <WhatsNewLink
                    key={el.title}
                    href={el.url || ''}
                    color='transparent'
                  >
                    <>
                      <ColumnContentImage
                        src={domain + el.image.path}
                        alt={el.image.title}
                      />
                      <ColumnContentTitle>{el.title}</ColumnContentTitle>
                    </>
                  </WhatsNewLink>
                ),
            )}
          </WhatsNewColumn>
        </>
      )}
    </HeroBlockContainer>
  );
};

export default HeroBlock;
