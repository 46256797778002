import React from 'react';
import { Grid } from 'theme-ui';
import { REPORTS_LIST_URL } from '../../../consts/urls';
import { ListItemDivider } from '../../../shared-styled/blocks/listPagesBlocks';
import { NoUnderlineAnchor } from '../../../shared-styled/typography/Anchors';
import PostPreview from '../../PostPreview';
import UniversalLink from '../../UniversalLink/UniversalLink';
import {
  ReportsBlockContainer,
  ReportsBlockTitle,
} from './ReportsBlock.styled';

const ReportsBlock = ({ reportsData }) => (
  <ReportsBlockContainer>
    <UniversalLink href={REPORTS_LIST_URL} passHref>
      <NoUnderlineAnchor>
        <ReportsBlockTitle>Reports</ReportsBlockTitle>
      </NoUnderlineAnchor>
    </UniversalLink>
    <Grid
      columns={['2fr', '2fr', '1fr 1fr']}
      p={['70px 0 30px']}
      gap={['20px', '40px', '90px']}
    >
      {reportsData
        ? reportsData.map((report) => (
            <div key={report.slug}>
              <PostPreview {...report} href={`/reports/${report.slug}`} />
              <ListItemDivider />
            </div>
          ))
        : null}
    </Grid>
  </ReportsBlockContainer>
);

export default ReportsBlock;
