import React, { useMemo } from 'react';

import { useGlobalContext } from '../../components/GlobalContextProvider/GlobalContextProvider';
import AgitationBlock from '../../components/HomePageBlocks/AgitationBlock/AgitationBlock';
import HeroBlock from '../../components/HomePageBlocks/HeroBlock/HeroBlock';
import NewsBlock from '../../components/HomePageBlocks/NewsBlock/NewsBlock';
import ReportsBlock from '../../components/HomePageBlocks/ReportsBlock/ReportsBlock';
import WeaponsBlock from '../../components/HomePageBlocks/WeaponsBlock/WeaponsBlock';
import { htmlTagRegEx } from '../../consts/regex';
import insertSpaceAfterPunctuation from '../../utils/insertSpaceAfterPunctuation';
import textTrimmer from '../../utils/textTrimmer';
import titleToSlug from '../../utils/titleToSlug';
import BasePageWrap from '../BasePage';
import {
  Wrapper,
  ContentContainer,
  WhatsNewBlockTitle,
  ContentDescription,
  WhatsNewBlock,
  ContentBlock,
  ContentImage,
  ContentTitle,
  ContentLink,
} from './HomePage.styled';

const domain = process.env.NEXT_PUBLIC_HOST_ENV
  ? `https://${process.env.NEXT_PUBLIC_HOST_ENV}`
  : 'https://dev.mp.quintagroup.com';

const HomePage = ({ whatsNew, body, reportsData, newsData }) => {
  const DESCRIPTION_PREVIEW_LENGTH = 175;

  const { isLogged } = useGlobalContext();

  const heroBlocksData = useMemo(
    () => body.filter((el) => el.type === 'hero_block', [body]),
    [body],
  );
  const trustedSourceData = heroBlocksData[0]?.value || {};
  const agitationBlockData = heroBlocksData[1]?.value || {};
  const weaponsBlockData = useMemo(
    () => body.find((el) => el.type === 'content_block', [body])?.value,
    [body],
  );

  const whatsNewArray = [
    {
      title: whatsNew.titleOne,
      description: whatsNew.descriptionOne,
      image: whatsNew.imageOne,
      url: `whats-new/#${titleToSlug(whatsNew.titleOne)}`,
    },
    {
      title: whatsNew.titleTwo,
      description: whatsNew.descriptionTwo,
      image: whatsNew.imageTwo,
      url: `whats-new/#${titleToSlug(whatsNew.titleTwo)}`,
    },
    {
      title: whatsNew.titleThree,
      description: whatsNew.descriptionThree,
      image: whatsNew.imageThree,
      url: `whats-new/#${titleToSlug(whatsNew.titleThree)}`,
    },
  ];

  return (
    <Wrapper>
      {Object.keys(trustedSourceData).length > 0 && (
        <HeroBlock
          whatsNewData={whatsNewArray}
          trustedSourceData={trustedSourceData}
          weaponsBlockData={weaponsBlockData}
        />
      )}

      {!isLogged && (
        <>
          {weaponsBlockData && <WeaponsBlock data={weaponsBlockData} />}
          {Object.keys(agitationBlockData).length > 0 && (
            <AgitationBlock data={agitationBlockData} />
          )}
        </>
      )}

      <WhatsNewBlock>
        <WhatsNewBlockTitle>What`s New</WhatsNewBlockTitle>
        <ContentContainer>
          {whatsNewArray.map(
            (el) =>
              el.image && (
                <ContentLink key={el.title} href={el.url || ''}>
                  <ContentBlock>
                    <ContentImage
                      src={domain + el.image.path}
                      alt={el.image.title}
                    />
                    <ContentTitle>{el.title}</ContentTitle>
                    <ContentDescription
                      html={
                        el.description &&
                        textTrimmer(
                          insertSpaceAfterPunctuation(
                            el.description.replace(htmlTagRegEx, ''),
                          ),
                          DESCRIPTION_PREVIEW_LENGTH,
                        )
                      }
                    />
                  </ContentBlock>
                </ContentLink>
              ),
          )}
        </ContentContainer>
      </WhatsNewBlock>

      <ReportsBlock reportsData={reportsData} />
      <NewsBlock newsData={newsData} />
    </Wrapper>
  );
};

export default BasePageWrap(HomePage);
