import { css, Flex } from 'theme-ui';
import theme from '../../../lib/theme';
import styled from '../../../lib/transient-styled';
import { TextButton } from '../../../shared-styled/blocks/buttons';
import pxToRem from '../../../utils/pixelsToRem';
import RawHtml from '../../RawHtml';

const domain = process.env.NEXT_PUBLIC_HOST_ENV
  ? `https://${process.env.NEXT_PUBLIC_HOST_ENV}`
  : 'https://dev.mp.quintagroup.com';

export const AgitationBlockContainer = styled(Flex)(
  css({
    position: 'relative',
    width: '100%',
    height: '428px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    fontFamily: 'Lato',
    color: 'text',

    '&::before': {
      content: '""',
      display: 'block',
      height: '100%',
      position: 'absolute',
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      backdropFilter: 'blur(5px)',
    },
  }),
  ({ $background }) =>
    css({
      backgroundImage: `url(${domain + $background.path})`,
    }),
);

export const AgitationBlockBody = styled(Flex)(
  css({
    width: '100%',
    flexDirection: 'column',
    gap: '65px',
    mt: '74px',
    ml: '106px',
    zIndex: '3',
    [`@media (max-width: ${theme.breakpoints[1]})`]: {
      alignItems: 'center',
      mx: '25px',
      mt: '35px',
      gap: '35px',
    },
  }),
);

export const AgitationBlockTitle = styled.h2(
  css({
    fontWeight: '300',
    fontSize: pxToRem(30),
    lineHeight: pxToRem(40),
    letterSpacing: '0.01em',
    textAlign: ['center', 'left'],
  }),
);

export const RelativeAgitationBlock = styled.div(
  css({
    position: 'relative',
    width: ['280px', '475px'],
    ml: [0, '76px'],
  }),
);

export const AgitationBlockDescription = styled(RawHtml)(
  css({
    '& p': {
      lineHeight: pxToRem(28),
      letterSpacing: '0.01em',
      textAlign: ['justify', 'left'],
    },
  }),
);

export const AgitationBlockSeparator = styled.span(
  css({
    position: 'absolute',
    top: '-8px',
    left: '-32px',
    width: '1px',
    height: '63px',
    borderLeft: '1px solid',
    borderColor: 'text',
  }),
);

export const AgitationButtonWrapper = styled.div(
  css({
    ml: [0, '100px'],
  }),
);

export const AlignedTextButton = styled(TextButton)(
  css({
    px: '26px',
    py: '18px',
  }),
);
