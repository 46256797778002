import { useBreakpointIndex } from '@theme-ui/match-media';
import React, { useCallback } from 'react';
import { MOBILE_BREAKPOINT_INDEX } from '../../../consts/style-consts';
import { IS_NEXT_EXPORT } from '../../../utils/export';
import ConditionalWrapper from '../../ConditionalWrapper/ConditionalWrapper';
import UniversalLink from '../../UniversalLink/UniversalLink';
import {
  WeaponsBlockContainer,
  WeaponsHead,
  WeaponsHeadTitle,
  WeaponsHeadBody,
  WeaponsHeadDescription,
  WeaponsHeadSeparator,
  WeaponsBody,
  Weapon,
  WeaponTextColumn,
  WeaponImageColumn,
  WeaponTextDescription,
  WeaponTextTitle,
  WeaponMobileImageWrapper,
  WeaponMobileWrapper,
  WeaponsImage,
} from './WeaponsBlock.styled';

const domain = process.env.NEXT_PUBLIC_HOST_ENV
  ? `https://${process.env.NEXT_PUBLIC_HOST_ENV}`
  : 'https://dev.mp.quintagroup.com';

const WeaponsBlock = ({ data }) => {
  const index = useBreakpointIndex({ defaultIndex: IS_NEXT_EXPORT ? 2 : 0 });

  const imageLoader = useCallback(
    ({ src, width, quality }) =>
      `${domain}${src}?w=${width}&q=${quality || 75}`,
    [],
  );

  return (
    <WeaponsBlockContainer>
      <WeaponsHead>
        <WeaponsHeadTitle>{data.headTitle}</WeaponsHeadTitle>
        <WeaponsHeadBody>
          <WeaponsHeadDescription html={data.headDescription} />
          <WeaponsHeadSeparator />
        </WeaponsHeadBody>
      </WeaponsHead>
      <WeaponsBody>
        {data.dynamicContent.map((el) => (
          <Weapon key={el.value.title}>
            {index <= MOBILE_BREAKPOINT_INDEX ? (
              <WeaponMobileWrapper>
                <WeaponMobileImageWrapper
                  image={(IS_NEXT_EXPORT ? domain : '') + el.value.image.path}
                >
                  <ConditionalWrapper
                    condition={!!el.value.link.path}
                    Wrapper={UniversalLink}
                    wrapperProps={{
                      href: el.value.link.path,
                    }}
                  >
                    <WeaponTextTitle $isLink={!!el.value.link.path}>
                      {el.value.title}
                    </WeaponTextTitle>
                  </ConditionalWrapper>
                </WeaponMobileImageWrapper>
                <WeaponTextDescription html={el.value.description} />
              </WeaponMobileWrapper>
            ) : (
              <>
                <WeaponTextColumn>
                  <ConditionalWrapper
                    condition={!!el.value.link.path}
                    Wrapper={UniversalLink}
                    wrapperProps={{
                      href: el.value.link.path,
                    }}
                  >
                    <WeaponTextTitle $isLink={!!el.value.link.path}>
                      {el.value.title}
                    </WeaponTextTitle>
                  </ConditionalWrapper>
                  <WeaponTextDescription html={el.value.description} />
                </WeaponTextColumn>
                <WeaponImageColumn>
                  <ConditionalWrapper
                    condition={!!el.value.link.path}
                    Wrapper={UniversalLink}
                    wrapperProps={{
                      href: el.value.link.path,
                    }}
                  >
                    <WeaponsImage
                      loader={imageLoader}
                      width={590}
                      height={350}
                      alt={el.value.image.title}
                      src={(IS_NEXT_EXPORT ? domain : '') + el.value.image.path}
                      quality={100}
                      $isLink={!!el.value.link.path}
                    />
                  </ConditionalWrapper>
                </WeaponImageColumn>
              </>
            )}
          </Weapon>
        ))}
      </WeaponsBody>
    </WeaponsBlockContainer>
  );
};

export default WeaponsBlock;
